import './PrivacyPolicy.css';

export const PrivacyPolicy = () => {
  return <>
    <div className="privacy-policy-container">
      <h1>123GAMES GPTs Privacy Policy</h1>
      <b>Effective Date: January 19, 2024</b>

      <h2>Introduction</h2>
      <p>
        Welcome to 123GAMES, a gaming company. This Privacy Policy applies to users of On Mars GPT and is provided by 123GAMES. We are dedicated to safeguarding the privacy and data of our users while interacting with our game.
      </p>

      <h2>Data Handling Practices</h2>
      <h3>Authentication:</h3>
      <p>Players are required to set a made-up username for gameplay. No personal data beyond the chosen username is stored by 123GAMES.</p>

      <h3>Data Collection:</h3>
      <p>We do not actively collect personal information. Interaction data, including gameplay choices, text inputs, and in-game actions, are collected solely to enhance user experience and improve our service.</p>

      <h3>Data Storage:</h3>
      <p>All collected data is securely stored, prioritizing data security and privacy integrity, within the United States.</p>

      <h2>Content Generation and Rights</h2>
      <h3>In-Game Content Creation:</h3>
      <p>On Mars includes content generation based on user interactions. Content created in-game is exclusive to the user's gameplay experience.</p>

      <h3>Anonymity and Access:</h3>
      <p>The content generated within the game is tied to the user's chosen username. 123GAMES cannot link in-game actions or content to a specific real-world identity.</p>

      <h3>Rights and Distribution:</h3>
      <p>In-game generated content remains the property of 123GAMES. However, we respect the intellectual contributions of our users and endeavor to maintain the spirit of their gameplay in our use of such content.</p>

      <h2>Data Sharing</h2>
      <p>Data required for gameplay, such as user inputs and in-game decisions, may be processed by AI service providers. This data sharing is confined to gameplay content.</p>

      <h2>Policy Modifications</h2>
      <p>123GAMES reserves the right to modify this Privacy Policy in response to changes in legal, operational, or regulatory directives. Any modifications will be effective immediately upon posting.</p>

      <h2>Contact Information</h2>
      <p>For more information about our privacy practices, inquiries, or complaints, please contact: contact@123games.fun</p>
    </div>
  </>
}